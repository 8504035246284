// Gatsby supports TypeScript natively!
import React, { useRef } from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"


type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const PrivacyIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  return (
        <Layout location={location} title={siteTitle}>
          {/* <div style={{width: 400, height: 400, background: `url(${data.hero_image.childImageSharp.fixed})`}}></div> */}
          <SEO title="Dev Internship Dotcom" />
          <h2>Privacy</h2>
        <div>
          <p>
            This Privacy Policy governs the manner in which the website collects, uses, maintains
            and discloses information collected from users (each, a ‘User’) of the website (‘Site’).
            This privacy policy applies to the Site and all products and services offered by
            company.
          </p>
          <p>Personal identification information</p>
          <p>
            We may collect personal identification information from Users in a variety of ways,
            including, but not limited to, when Users visit our site, register on the site,
            subscribe to the newsletter, and in connection with other activities, services, features
            or resources we make available on our Site. Users may be asked for, as appropriate,
            email address. Users may, however, visit our Site anonymously. We will collect personal
            identification information from Users only if they voluntarily submit such information
            to us. Users can always refuse to supply personally identification information, except
            that it may prevent them from engaging in certain Site related activities.
          </p>
          <p>Non-personal identification information</p>
          <p>
            We may collect non-personal identification information about Users whenever they
            interact with our Site. Non-personal identification information may include the browser
            name, the type of computer and technical information about Users means of connection to
            our Site, such as the operating system and the Internet service providers utilized and
            other similar information.
          </p>
          <p>Web browser cookies</p>
          <p>
            Our Site may use ‘cookies’ to enhance User experience. User’s web browser places cookies
            on their hard drive for record-keeping purposes and sometimes to track information about
            them. User may choose to set their web browser to refuse cookies, or to alert you when
            cookies are being sent. If they do so, note that some parts of the Site may not function
            properly.
          </p>
          <p>How we use collected information</p>
          <p>Company may collect and use Users personal information for the following purposes:</p>
          <ul>
            <li>
              To improve customer service Information you provide helps us respond to your customer
              service requests and support needs more efficiently.
            </li>
            <li>
              To personalize user experience We may use information in the aggregate to understand
              how our Users as a group use the services and resources provided on our Site.
            </li>
            <li>
              To send periodic emails We may use the email address to send User information and
              updates pertaining to their order. It may also be used to respond to their inquiries,
              questions, and/or other requests. If User decides to opt-in to our mailing list, they
              will receive emails that may include company news, updates, related product or service
              information, etc. If at any time the User would like to unsubscribe from receiving
              future emails, we include detailed unsubscribe instructions at the bottom of each
              email or User may contact us via our Site. How we protect your information
            </li>
          </ul>
          <p>
            We adopt appropriate data collection, storage and processing practices and security
            measures to protect against unauthorized access, alteration, disclosure or destruction
            of your personal information, username, password, transaction information and data
            stored on our Site.
          </p>
          <p>Sharing your personal information</p>
          <p>
            We do not sell, trade, or rent Users personal identification information to others. We
            may share generic aggregated demographic information not linked to any personal
            identification information regarding visitors and users with our business partners,
            trusted affiliates and advertisers for the purposes outlined above.
          </p>
          <p>Third party websites</p>
          <p>
            Users may find advertising or other content on our Site that link to the sites and
            services of our partners, suppliers, advertisers, sponsors, licensors and other third
            parties. We do not control the content or links that appear on these sites and are not
            responsible for the practices employed by websites linked to or from our Site. In
            addition, these sites or services, including their content and links, may be constantly
            changing. These sites and services may have their own privacy policies and customer
            service policies. Browsing and interaction on any other website, including websites
            which have a link to our Site, is subject to that website’s own terms and policies.
          </p>
          <p>Changes to this privacy policy</p>
          <p>
            Company has the discretion to update this privacy policy at any time. When we do, we
            will revise the updated date at the bottom of this page. We encourage Users to
            frequently check this page for any changes to stay informed about how we are helping to
            protect the personal information we collect. You acknowledge and agree that it is your
            responsibility to review this privacy policy periodically and become aware of
            modifications.
          </p>
          <p>Your acceptance of these terms</p>
          <p>
            By using this Site, you signify your acceptance of this policy. If you do not agree to
            this policy, please do not use our Site. Your continued use of the Site following the
            posting of changes to this policy will be deemed your acceptance of those changes.
          </p>
        </div>
        <h1>Terms and Conditions</h1>
        <div class="jsx-3750719020 bodytext">
          <p>Web Site Terms and Conditions of Use</p>
          <ol>
            <li>
              Terms By accessing this web site, you are agreeing to be bound by these web site Terms
              and Conditions of Use, all applicable laws and regulations, and agree that you are
              responsible for compliance with any applicable local laws. If you do not agree with
              any of these terms, you are prohibited from using or accessing this site. The
              materials contained in this web site are protected by applicable copyright and trade
              mark law.
            </li>
            <li>
              Use License Permission is granted to temporarily use of materials (information or
              software) on Company’s web site for personal, non-commercial transitory viewing only.
              This is the grant of a license, not a transfer of title, and under this license you
              may not: modify or copy the materials; use the materials for any commercial purpose,
              or for any public display (commercial or non-commercial); attempt to decompile or
              reverse engineer any software contained on Company’s web site; remove any copyright or
              other proprietary notations from the materials; or transfer the materials or account
              to another person or ‘mirror’ the materials on any other server. This license shall
              automatically terminate if you violate any of these restrictions and may be terminated
              by Company at any time.
            </li>
            <li>
              Disclaimer The materials on Company’s web site are provided ‘as is’. Company makes no
              warranties, expressed or implied, and hereby disclaims and negates all other
              warranties, including without limitation, implied warranties or conditions of
              merchantability, fitness for a particular purpose, or non-infringement of intellectual
              property or other violation of rights. Further, Company does not warrant or make any
              representations concerning the accuracy, likely results, or reliability of the use of
              the materials on its Internet web site or otherwise relating to such materials or on
              any sites linked to this site.
            </li>
            <li>
              Limitations In no event shall Company or its suppliers be liable for any damages
              (including, without limitation, damages for loss of data or profit, or due to business
              interruption,) arising out of the use or inability to use the materials on Company’s
              Internet site, even if Company or a Company authorized representative has been
              notified orally or in writing of the possibility of such damage. Because some
              jurisdictions do not allow limitations on implied warranties, or limitations of
              liability for consequential or incidental damages, these limitations may not apply to
              you.
            </li>
            <li>
              Revisions and Errata The materials appearing on Company’s web site could include
              technical, typographical, or photographic errors. Company does not warrant that any of
              the materials on its web site are accurate, complete, or current. Company may make
              changes to the materials contained on its web site at any time without notice. Company
              does not, however, make any commitment to update the materials.
            </li>
            <li>
              Links Company has not reviewed all of the sites linked to its Internet web site and is
              not responsible for the contents of any such linked site. The inclusion of any link
              does not imply endorsement by Company of the site. Use of any such linked web site is
              at the user’s own risk.
            </li>
            <li>
              Site Terms of Use Modifications Company may revise these terms of use for its web site
              at any time without notice. By using this web site you are agreeing to be bound by the
              then current version of these Terms and Conditions of Use.
            </li>
            <li>
              Governing Law Any claim relating to Company’s web site shall be governed by the laws
              of the State of New York without regard to its conflict of law provisions.
            </li>
          </ol>
        </div>
        </Layout>

  )
}

export default PrivacyIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
